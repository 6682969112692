<!--
 * @Author: hwu
 * @Date: 2021-01-26 10:17:09
 * @Description: 点餐页面 -> pos机扫码点餐
 * @LastEditTime: 2021-06-16 10:22:01
-->
<template>
  <div class="app-container">
    <div class="scan-box" :style="{ backgroundImage: 'url(' + transferImage(guideBgUrl) + ')' }">
      <img class="brand-logo" :src="transferImage(storeInfo.brandLogo, 'logo')">
      <button class="scan-btn" @click="scanQrCode">开始点餐<i class="iconfont iconchakan" /></button>
    </div>
  </div>
</template>
<script>
import { scanQrCodeApi, createScanDeviceConnectionApi } from '@/api/apk'
import { getStoreInfoApi, getTableByQrCodeApi } from '@/api/store'
export default {
  data () {
    return {
      storeId: '',
      userId: '',
      storeInfo: {},
      storeName: '',
      entranceType: 1,
      guideBgUrl: '294ebb372c0240f3944a0bb1f410d595'
    }
  },
  created () {
    this.storeId = this.$route.query.storeId
    this.userId = this.$route.query.userId
    if (!this.storeId || !this.userId) {
      this.$linkToError('服务异常，请重新登录')
      return false
    }
    // 检查点餐环境
    this.$checkEntranceType(this.entranceType)
    // 获取门店信息
    this.getStoreInfo()

    if (this.$bappSdk.isBApp()) {
      window.scanCallBack = this.getTableByQrCode
    }
    // 开启扫码枪连接
    createScanDeviceConnectionApi('1', '')
    // 监听扫码回调通知
    window.addEventListener('message', this.scanTableCallBack, false)
  },
  beforeDestroy () {
    // 关闭扫码枪连接
    createScanDeviceConnectionApi('0', '')
    window.removeEventListener('message', this.scanTableCallBack, false)
  },
  methods: {
    getStoreInfo () {
      getStoreInfoApi(this.storeId).then((res) => {
        if (res.status !== 0 || !res.data) {
          return false
        }
        this.storeInfo = res.data
      })
    },
    transferImage (key, type) {
      if (!key && type === 'logo') {
        return require('@/assets/images/logo.png')
      }
      return process.env.VUE_APP_QNY_LOAD_URL + key
    },
    scanQrCode () {
      scanQrCodeApi()
    },
    scanTableCallBack (event) {
      if (event.data.state !== 'scanCallBack') {
        return false
      }
      const url = event.data.code
      this.getTableByQrCode(url)
    },
    getTableByQrCode (url) {
      const path = url.split('/')
      if (!path) {
        this.$toast('无效的桌码')
        return false
      }
      const codeId = path[path.length - 1]
      getTableByQrCodeApi(codeId).then((res) => {
        if (res.status !== 0 || !res.data) {
          this.$toast('无效的桌码')
          return false
        }
        const tableInfo = res.data
        if (tableInfo.storeId !== this.storeId) {
          this.$toast('请扫描本店桌码下单')
          return false
        }
        this.$router.push({
          name: 'OrderGuide',
          query: {
            storeId: this.storeId,
            userId: this.userId,
            entranceType: this.entranceType,
            tableId: tableInfo.tableId,
            tableName: tableInfo.tableName
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.scan-box {
  width: 100%;
  height: 100vh;
  text-align: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .scan-btn {
    position: absolute;
    bottom: 108px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-content: center;
    justify-content: center;
    width: 320px;
    height: 88px;
    line-height: 88px;
    background: $color-primary;
    font-weight: 600;
    font-size: 30px;
    color: $color-white;
    border-radius: 16px;
    border: 2px solid $color-white;
    z-index: 20;
    i {
      margin-left: 24px ;
    }
  }
  .brand-logo {
    position: fixed;
    top: 170px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    border-radius: 8px;
  }
}
</style>
