/*
 * @Author: hwu
 * @Date: 2020-11-26 17:12:22
 * @Description: 门店接口
 * @LastEditTime: 2023-02-28 17:57:43
 */
import { posHttp, crmHttp } from '@/utils/axios'

/**
 * 获取门店信息
 * @param {String} storeId 门店Id
 */
export const getStoreInfoApi = (storeId) => posHttp.get(`/store/info?storeId=${storeId}`)

/**
 * 门店员工列表
 * @param {String} storeId 门店Id
 */
export const listStoreWaitersApi = (storeId) => posHttp.get(`/store/getStoreWaiters?storeId=${storeId}`)

/**
 * 服务员登录
 * @param {Object} data 登录表单
 */
export const loginWaiterApi = (data) => posHttp.post(`/store/waiterLogin`, data)

/**
 * 获取该品牌下所有城市
 * @param brandCode 品牌编码，多个品牌逗号隔开
 * @returns {*}
 */
export const listBrandCitiesApi = (brandCode = '', promoId = '', cardId = '') => posHttp.get('/store/brandCities', { params: { brandCode, promoId, cardId } })

/**
 * 获取该品牌，城市下的门店列表，并计算距离
 * @param lng 纬度
 * @param lat 经度
 * @param cityId 城市id
 * @param brandCode 品牌编码
 * @returns {*}
 */
export const listCityBrandStores = (lng, lat, cityId, brandCode) => posHttp.get('/store/storeList', { params: { lng, lat, cityId, brandCode } })

/**
 * 通过用户ip地址获取用户所在城市
 * @returns {*}
 */
export const getCityByIpApi = () => posHttp.get(`/store/city/by/ip`)

/**
 * 根据二维码获取桌位信息
 * @param {String} qrCode 桌码后面的那串key
 */
export const getTableByQrCodeApi = (qrCode) => posHttp.get('/store/table/by/code', { params: { qrCode } })

/**
 * 获取门店支付方式
 * @param {String} storeId 门店Id
 * @returns
 */
export const listStorePayWaysApi = (storeId) => posHttp.get('/store/payWays', { params: { storeId } })

/**
 *获取门店列表(会员卡适用门店弹窗)
 * @param {String} brandCode 品牌编号
 */
export const getStoreComponentDataApi = (brandCode) => crmHttp.get(`/store/component/data`, { params: { brandCode } })
